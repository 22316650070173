import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import HeroBg from '../../assets/images/hero-background.svg';
import Container from 'common/src/components/Container';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Button from 'common/src/components/Button';
import UniversalLink from '../../components/UniversalLink';
import Icon from 'react-icons-kit';
import { longArrowRight } from 'react-icons-kit/fa/longArrowRight';

const Hero = ({
  row,
  col,
  title,
  subtitle,
  description,
  buttonOutline,
}) => {
  return (
    <SectionWrapper as="section">
      <Container className="hero">
        <Box {...row}>
          <Heading {...title} content="Hello, my name is Ruben" as="h1" />
          <Heading {...subtitle} content="Web developer with a background in user experience design" as="h2" />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <Text
              {...description}
              content="
                I specialize in creating exceptional websites, web applications, and front-end development.
                Since the beginning of my journey as a freelance web developer 10 years ago, I have done work for agencies, small businesses and startups.
              "
            />
            <UniversalLink to="/about/">
              <Button
                title="More About Me"
                variant="outlined"
                className="btn"
                icon={<Icon icon={longArrowRight} size={17} />}
                {...buttonOutline}
              />
            </UniversalLink>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

Hero.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  subtitle: PropTypes.object,
  description: PropTypes.object,
  buttonOutline: PropTypes.object,
};

Hero.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'left',
    justifyContent: 'left',
  },
  col: {
    width: ['100%', '100%', '100%', '65%', '60%'],
  },
  title: {
    fontSize: ['36px', '46px', '48px', '58px', '68px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.02em',
    lineHeight: '1.2',
    textAlign: 'left',
  },
  subtitle: {
    fontSize: ['26px', '28px', '32px', '36px', '40px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.02em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'left',
  },
  description: {
    fontSize: ['16px', '17px', '17px', '20px', '20px'],
    color: '#fff',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'left',
  },
  buttonOutline: {
    type: 'button',
    colors: 'primary',
    mt: '35px',
  },
};

const SectionWrapper = styled.section`
  min-height: 80vh;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 6vh;
  background-image: url(${HeroBg}),
    linear-gradient(95deg, ${themeGet('colors.greenNormal', '#04aa60')} 0%, ${themeGet('colors.greenLight', '#68cc9f')} 100%);
  background-size: cover;
  background-position: center bottom;
  .hero {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export default Hero;