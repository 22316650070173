import React from 'react';
import SEO from '../components/SEO';
import Layout from '../layouts/full-width';
import Hero from '../containers/Hero';
import WhatIDo from '../containers/WhatIDo';
import FeaturedWork from '../containers/FeaturedWork';
import GetInTouch from '../containers/GetInTouch';

const HomePage = () => {
  return (
    <Layout>
      <SEO
        title="Home"
        description="The Home Page"
      />
      <Hero />
      <WhatIDo />
      <FeaturedWork />
      <GetInTouch />
    </Layout>
  );
};

export default HomePage;
