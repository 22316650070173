import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Container from 'common/src/components/Container';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Button from 'common/src/components/Button';
import UniversalLink from '../../components/UniversalLink';
import CustomHeading from '../../layouts/CustomHeading';
import Icon from 'react-icons-kit';
import { longArrowRight } from 'react-icons-kit/fa/longArrowRight';

const FeaturedWork = ({
  buttonOutline
}) => {
  const data = useStaticQuery(graphql`
    query GET_FEATURED_PORTFOLIO_ITEMS {
      wpgraphql {
        portfolioItems(where: { in: [240, 1040, 241] }) {
          nodes {
            id
            databaseId
            title
            link
            uri
            slug
            date
            excerpt
            content
            imageSquare: featuredImage {
              node {
                sourceUrl
                altText
                imageFile {
                  childImageSharp {
                    fluid(
                      quality: 100
                      maxWidth: 1920
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            portfolioBuilder {
              subtitle
            }
            categories: portfolioCategories {
              nodes {
                name
                slug
                id
              }
            }
            tags: portfolioTags {
              nodes {
                name
                slug
                id
              }
            }
          }
        }
        mediaItems(where: { in: [3848, 3920, 3936] }) {
          nodes {
            sourceUrl
            altText
            imageFile {
              childImageSharp {
                fluid(
                  quality: 100
                  maxWidth: 1920
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  const featuredPortfolioItems = data?.wpgraphql.portfolioItems?.nodes?.map((item, index) => {
    item.imageWide = {};
    item.imageWide.node = data?.wpgraphql.mediaItems?.nodes[index];
    item.sources = [
      item.imageWide.node.imageFile.childImageSharp.fluid,
      {
        ...item.imageSquare.node.imageFile.childImageSharp.fluid,
        media: `(max-width: 767px)`,
      },
    ];
    return item;
  });

  return (
    <SectionWrapper as="section">
      <Container width="">
        <HeadingWrapper>
          <StyledCustomHeading
            text="Featured work"
            textElement="h2"
            textSize="small"
            textTransform="uppercase"
            textDecoration="underline"
          />
        </HeadingWrapper>
        <PortfolioWrapper>
          {featuredPortfolioItems.map((item, index) => (
            <PortfolioItem key={`item-${index}`}>
              <ImageWrapper className="image">
                <Img fluid={item.sources} />
              </ImageWrapper>
              <DescriptionWrapper className="description">
                <Subheading className="subheading">{item.portfolioBuilder.subtitle}</Subheading>
                <Heading content={item.title} as="h3" />
                <Description content={item.excerpt} />
                <UniversalLink to={item.uri}>
                  <Button
                    title="View Project"
                    variant="outlined"
                    className="btn"
                    icon={<Icon icon={longArrowRight} size={17} />}
                    {...buttonOutline}
                  />
                </UniversalLink>
              </DescriptionWrapper>
            </PortfolioItem>
          ))}
        </PortfolioWrapper>
        <ButtonWrapper>
          <UniversalLink to="/portfolio/">
            <Button
              title="View All Projects"
              className="btn btn-large"
              type="button"
              colors="primaryWithBg"
              icon={<Icon icon={longArrowRight} size={20} />}
            />
          </UniversalLink>
        </ButtonWrapper>
      </Container>
    </SectionWrapper>
  );
};

FeaturedWork.propTypes = {
  buttonOutline: PropTypes.object
};

FeaturedWork.defaultProps = {
  buttonOutline: {
    type: 'button',
    colors: 'primary',
  },
};

const SectionWrapper = styled(Box)`
  background-color: ${themeGet('colors.sectionBgSecondary', '#f5f5f5')};
`;

const HeadingWrapper = styled.div`
  text-align: center;
`;

const StyledCustomHeading = styled(CustomHeading)`
  margin-bottom: 5em;
`;

const PortfolioWrapper = styled.div``;

const PortfolioItem = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 0;
  grid-auto-flow: row;
  margin: 0 0 5.555rem;
  &:nth-child(odd) .image {
    margin-left: -10%;
    grid-row: 1;
    grid-column: 2;
  }
  &:nth-child(even) .image {
    margin-right: -10%;
  }
  &:nth-child(odd) .description {
    padding-right: 20%;
    grid-row: 1;
    grid-column: 1;
  }
  &:nth-child(even) .description {
    padding-left: 20%;
  }

  @media only screen and (max-width: 989px) {
    margin-bottom: 50px;
    grid-template-columns: 1fr;
    &:nth-child(odd) .image,
    &:nth-child(odd) .description {
      grid-row: auto;
      grid-column: auto;
    }
    &:nth-child(odd) .description,
    &:nth-child(even) .description {
      padding: 10%;
      margin: 0;
    }
    &:nth-child(odd) .image,
    &:nth-child(even) .image {
      margin: 0;
    }
  }
`;

const ImageWrapper = styled.div`
  z-index: 100;
  padding-top: 10%;
  @media only screen and (max-width: 989px) {
    padding-top: 0;
  }
  @media only screen and (min-width: 768px) {
    .gatsby-image-wrapper{
      box-shadow: 0 15px 30px rgba(0,0,0,0.2);
    }
  }
`;

const DescriptionWrapper = styled.div`
  padding: 10%;
  background-color: ${themeGet('colors.primary', '#09142e')};
  color: ${themeGet('colors.white', '#ffffff')};
`;

const Subheading = styled.p`
  margin: 0;
  font-family: ${themeGet('fontHeading', "'Poppins', sans-serif")};
  font-weight: 700;
  font-size: 0.95rem;
  letter-spacing: -0.02em;
  opacity: 0.6;
`;

const Description = styled(Text)`
  margin: 3rem 0;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

export default FeaturedWork;
