import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Container from 'common/src/components/Container';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import ImageProcessor from '../../components/ImageProcessor';
import CustomHeading from '../../layouts/CustomHeading';

const WhatIDo = () => {
  const data = useStaticQuery(graphql`
    query GET_SERVICES_ICONS {
      wpgraphql {
        mediaItems(where: { in: [4232, 4234, 4236, 4238, 4240, 4242] }) {
          nodes {
            sourceUrl
            altText
            imageFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  const serviceIcons = data?.wpgraphql.mediaItems?.nodes;

  return (
    <SectionWrapper as="section">
      <Container width="">
        <StyledCustomHeading
          text="Services"
          textElement="h2"
          textSize="small"
          textTransform="uppercase"
          textDecoration="underline"
        />
        <TextWrapper>
          <Heading content="What can I do for you?" as="h3" />
          <Text
            content="
              When it comes down to web development, my goal is to create online experiences that are accessible on all devices,
              easy to use, and engaging. Have a look at some of my services and discover how I can help you.
            "
          />
        </TextWrapper>
        <ServicesWrapper>
          <Service>
            <ImageWrapper>
              <ImageProcessor image={serviceIcons[0]} />
            </ImageWrapper>
            <Heading content="WordPress Websites" className="" as="h3" />
            <Text content="Reliable, secure and easy to manage websites that meet business goals and ensure results." />
          </Service>
          <Service>
            <ImageWrapper>
              <ImageProcessor image={serviceIcons[1]} />
            </ImageWrapper>
            <Heading content="Tailor-made Websites" className="" as="h3" />
            <Text content="Fast, responsive websites built for an optimal user experience, tailored to exact requirements." />
          </Service>
          <Service>
            <ImageWrapper>
              <ImageProcessor image={serviceIcons[2]} />
            </ImageWrapper>
            <Heading content="Web Applications" className="" as="h3" />
            <Text content="Progressive web apps and hybrid mobile apps, built using modern open-source web technologies." />
          </Service>
          <Service>
            <ImageWrapper>
              <ImageProcessor image={serviceIcons[3]} />
            </ImageWrapper>
            <Heading content="Open Source Development" className="" as="h3" />
            <Text content="Various front-end development services. Experienced in Javascript, React, HTML, CSS, PHP." />
          </Service>
          <Service>
            <ImageWrapper>
              <ImageProcessor image={serviceIcons[4]} />
            </ImageWrapper>
            <Heading content="User Experience Research" className="" as="h3" />
            <Text content="Finding insights that help fulfill the needs and expectations of users, using various methods." />
          </Service>
          <Service>
            <ImageWrapper>
              <ImageProcessor image={serviceIcons[5]} />
            </ImageWrapper>
            <Heading content="User Experience Design" className="" as="h3" />
            <Text content="Wireframing and prototyping. Iterative, user-oriented design to uncover problems and design opportunities." />
          </Service>
        </ServicesWrapper>
      </Container>
    </SectionWrapper>
  );
};

const SectionWrapper = styled(Box)`
  text-align: center;
`;

const StyledCustomHeading = styled(CustomHeading)`
  margin-bottom: 5em;
`;

const TextWrapper = styled(Box)`
  margin-bottom: 4em;
  @media only screen and (min-width:990px) {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
`;

const ServicesWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  justify-content: center;
  @media only screen and (max-width: 690px) {
    grid-template-columns: 1fr;
  }
`;

const Service = styled.div`
  margin-bottom: 2em;
  h3 {
    margin: 1.38rem 0;
    font-size: 1.125rem;
  }
`;

const ImageWrapper = styled.div`
  max-width: 150px;
  margin: 0 auto .5rem;
`;

export default WhatIDo;
