import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import sanitizeHtml from 'sanitize-html';
import Heading from 'common/src/components/Heading';

const CustomHeading = ({
  className,
  text,
  textAlign,
  textColor,
  textElement,
  textSize,
  textTransform,
  textDecoration
}) => {
  const addAllClasses = [];
  const color =
    textColor === undefined
      ? themeGet('colors.textPrimary', '#343d48')
      : textColor;
  const classString =
    (textDecoration === 'underline'
      ? 'underlined-title '
      : '') + textSize;

  if (classString) {
    addAllClasses.push(classString);
  }
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <div style={{textAlign: textAlign}}>
      <Heading
        content={sanitizeHtml(text)}
        className={addAllClasses.join(' ')}
        as={textElement}
        style={{
          color: color,
          textTransform: textTransform
        }}
      />
    </div>
  );
};

const StyledCustomHeading = styled(CustomHeading)``;

export default StyledCustomHeading;
