import React from 'react';
import styled from 'styled-components';
import Container from 'common/src/components/Container';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import UniversalLink from '../../components/UniversalLink';
import CustomHeading from '../../layouts/CustomHeading';
import Icon from 'react-icons-kit';
import { longArrowRight } from 'react-icons-kit/fa/longArrowRight';
import { FaWordpress } from 'react-icons/fa';
import { FaNodeJs } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';
import { FaHtml5 } from 'react-icons/fa';
import { FaSass } from 'react-icons/fa';
import { FaPhp } from 'react-icons/fa';
import { FaFigma } from 'react-icons/fa';
// import { FaDocker } from 'react-icons/fa';

const GetInTouch = () => {
  const technologies = [
    FaWordpress,
    FaNodeJs,
    FaReact,
    FaHtml5,
    FaSass,
    FaPhp,
    FaFigma
    // FaDocker,
  ];

  return (
    <SectionWrapper as="section">
      <Container width="">
        <StyledCustomHeading
          text="Get in touch"
          textElement="h2"
          textSize="small"
          textTransform="uppercase"
          textDecoration="underline"
        />
        <TextWrapper>
          <Heading content="Tell me about your project" as="h3" />
          <Text content="Feel free to contact me regarding your website or project idea. Send me a message and find out how I can help you create meaningful online experiences." />
        </TextWrapper>
        <ButtonWrapper>
          <UniversalLink to="/contact/">
            <Button
              title="Contact Me"
              className="btn btn-large"
              type="button"
              colors="primaryWithBg"
              icon={<Icon icon={longArrowRight} size={20} />}
            />
          </UniversalLink>
        </ButtonWrapper>
        <TechnologiesWrapper>
          {technologies.map((icon, index) => (
            <ImageWrapper key={`technology-${index}`}>
              { icon() }
            </ImageWrapper>
          ))}
        </TechnologiesWrapper>
      </Container>
    </SectionWrapper>
  );
};

const SectionWrapper = styled(Box)`
  padding-bottom: 0;
  text-align: center;
`;

const StyledCustomHeading = styled(CustomHeading)`
  margin-bottom: 5em;
`;

const TextWrapper = styled(Box)`
  margin-bottom: 4em;
  @media only screen and (min-width:990px) {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
`;

const ButtonWrapper = styled(Box)`
  margin-bottom: 4em;
`;

const TechnologiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  font-size: 60px;
  position: relative;
  padding: 20px 28px;
  flex-shrink: 0;
  &:hover {
    svg {
      filter: grayscale(0);
      opacity: 1;
    }
  }
  svg {
    filter: grayscale(1);
    opacity: 0.5;
    transition: 0.3s ease-in-out;
  }
`;

export default GetInTouch;
